/* @file
 * SASS variables for Justice Network theme.
 */

// Variables for Neat.
$grid-columns: 12;
$max-width: 79rem;
$max-interior-width: 46rem;

// Color palette.
$light-grey: #ebedea;
$light-orange: #f7b500;
$dark-orange: #f27200;
$medium-grey: #58595b;
$dark-grey: #2f292b;

$background-dark-grey: #272727;
$background-light-grey: #dddcdc;
$yellow: #fff200;
$white: #ffffff;
$text-dark: #2e2f2f;
// $light-blue: #0097e9;
// $dark-blue: #053150;
$text-hover-grey: #a1a1a1;
// $yellow-on-now: rgb(255, 242, 0);

// Fonts.
$serif: "Georgia", "Times New Roman", serif;
$poppins: Poppins, sans-serif;
//$galano: "Galano Grotesque", Helvetica, Arial, sans-serif;
//$helvetica-neue: "Helvetica Neue", Helvetica, Arial, sans-serif;
$galano: $poppins;
$helvetica-neue: Helvetica, Arial, sans-serif;

// Master breakpoints (old - desktop first)
$breakpoint-large: new-breakpoint(max-width 65rem);
$breakpoint-medium: new-breakpoint(max-width 54rem);
$breakpoint-mobile: new-breakpoint(max-width 49rem);
$breakpoint-narrow: new-breakpoint(max-width 23rem);

// Master breakpoints
$breakpoint-min-mobile-wide: new-breakpoint(min-width 23rem);
$breakpoint-min-mobile-wider: new-breakpoint(min-width 26rem);
$breakpoint-min-medium: new-breakpoint(min-width 49rem);
$breakpoint-min-large: new-breakpoint(min-width 62rem);
$breakpoint-min-xlarge: new-breakpoint(min-width 73rem);
$breakpoint-min-full: new-breakpoint(min-width 84rem);
