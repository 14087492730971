@charset "UTF-8";
/* @file
 * Base stylesheet for Justice Network.
 */
/* normalize-scss 3.0.3+normalize.3.0.3 | MIT/GPLv2 License | bit.ly/normalize-scss */
html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

/* @file
 * SASS variables for Justice Network theme.
 */
/*
@function linear-gradient($angle, $details...) {
  $legacy-syntax: $use-legacy-gradient-syntax;

  @if type-of($angle) != 'number' {
    $angle: compact($angle);
    $legacy-syntax: if(index($angle, 'to'), false, true);
  }

  @if $legacy-syntax {
    @return _linear-gradient_legacy($angle, $details...);
  } @else {
    @return _linear-gradient($angle, $details...);
  }
}
 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/sites/all/themes/jntheme/images/ajax-loader.gif") center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("/sites/all/themes/jntheme/fonts/slick.eot");
  src: url("/sites/all/themes/jntheme/fonts/slick.eot?#iefix") format("embedded-opentype"), url("/sites/all/themes/jntheme/fonts/slick.woff") format("woff"), url("/sites/all/themes/jntheme/fonts/slick.ttf") format("truetype"), url("/sites/all/themes/jntheme/fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  margin-top: -10px\9 ;
  /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev:before {
  content: "←";
}
[dir=rtl] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next:before {
  content: "→";
}
[dir=rtl] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -45px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 */
html {
  font-family: Helvetica, Arial, sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 8/9.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active,
a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic;
}

/**
 * Heading font family.
 */
h1, h2, h3, h4, h5, h6 {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  /* Set the font-size and line-height while keeping a proper vertical rhythm. */
  font-size: 5.1 em;
  /* Set 1 unit of vertical rhythm on the top and bottom margins. */
  margin-top: 0.2745098039em;
  margin-bottom: 0.2745098039em;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *    Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Address `font-family` inconsistency between `textarea` and other form in IE 7
 * 5. Improve appearance and consistency with IE 6/7.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  box-sizing: content-box;
  /* 2 */
  /**
   * Remove inner padding and search cancel button in Safari and Chrome on OS X.
   * Safari (but not Chrome) clips the cancel button when the search input has
   * padding (and `textfield` appearance).
   */
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  margin: 0 2px;
  /* Apply borders and padding that keep the vertical rhythm. */
  border-color: #c0c0c0;
  border-top-width: 0.05em;
  border-top-style: solid;
  padding-top: 0.44em;
  border-bottom-width: 0.05em;
  border-bottom-style: solid;
  padding-bottom: 0.86em;
  border-left-width: 0.05em;
  border-left-style: solid;
  padding-left: 0.825em;
  border-right-width: 0.05em;
  border-right-style: solid;
  padding-right: 0.825em;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  padding: 0;
  /* 2 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

/**
 * Other Justice Network base styles.
 */
body {
  background-color: #58595b;
  color: #ffffff;
  letter-spacing: 0.1em;
  font-weight: 200;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1em;
}

a, a:visited {
  font-weight: 700;
  color: white;
}

label {
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}

.form-item-radios label.option {
  font-family: Helvetica, Arial, sans-serif;
  text-transform: none;
}

input[type=text], input[type=password], input[type=number], input[type=email], textarea {
  border: 1px solid white;
  background-color: #58595b;
  padding: 0.5em 0.5em 0.3em;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #f27200;
  background-image: url(../images/down-arrow.svg);
  background-size: 1em 0.4em;
  background-position: right;
  background-repeat: no-repeat;
  padding: 0.5em 1.25em 0.3em 0.5em;
}
select option {
  padding: 0.5em 1.25em 0.3em 0.5em;
  background-color: #f27200;
}

input[type=submit], input[type=button], a.button {
  font-family: Poppins, sans-serif;
  font-size: 0.7em;
  font-weight: normal;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: #f27200;
  border: none;
  padding: 0.8em;
}

a.button {
  display: inline-block;
  text-decoration: none;
  text-align: center;
}

/**
 * Font size reductions for narrower breakpoints.
 */
h1 {
  font-size: 45.6px;
}

h2 {
  font-size: 22.8px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 14.04px;
}

h5 {
  font-size: 12px;
}

h6 {
  font-size: 9.96px;
}

body {
  font-size: 12px;
  line-height: 16px;
}

@media screen and (min-width: 49rem) {
  h1 {
    font-size: 56px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 21px;
  }

  h4 {
    font-size: 16.38px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 11.62px;
  }

  body {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (min-width: 62rem) {
  h1 {
    font-size: 72px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 18.72px;
  }

  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 13.28px;
  }

  body {
    font-size: 16px;
    line-height: 23px;
  }
}
@media screen and (min-width: 73rem) {
  h1 {
    font-size: 88px;
  }

  h2 {
    font-size: 48px;
  }
}
@media screen and (min-width: 84rem) {
  h1 {
    font-size: 102px;
  }

  h2 {
    font-size: 40px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 23.4px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 16.6px;
  }

  body {
    font-size: 20px;
    line-height: 28px;
  }
}
/* Mimic admin theme for toolbar */
#toolbar {
  font-family: "Lucida Grande", Verdana, sans-serif;
  letter-spacing: 0;
}
#toolbar a {
  font-weight: normal;
}

/* @file
 * Layout sass file for Justice Network.
 */
body {
  text-align: center;
}

#skip {
  display: none;
}

#page {
  text-align: left;
  width: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

#main {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0;
}
#main .tabs {
  position: absolute;
  top: 0.3em;
  z-index: 20;
}

#content {
  margin: 0;
  padding: 0 1rem;
}
@media screen and (min-width: 23rem) {
  #content {
    padding: 0 2rem;
  }
}

.container-wrapper {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0 2.5em;
}
@media screen and (max-width: 23rem) {
  .container-wrapper {
    padding: 0 1.75em;
  }
}

.footer-wrapper {
  margin: 0 auto;
  max-width: 1360px;
  padding: 0;
}

#header {
  position: relative;
  display: table;
  width: 100%;
}
@media screen and (max-width: 49rem) {
  #header {
    display: block;
  }
}

.panel-2col {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
}
.panel-2col::after {
  clear: both;
  content: "";
  display: table;
}
.panel-2col .panel-col-first {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
}
.panel-2col .panel-col-first:last-child {
  margin-right: 0;
}
@media screen and (min-width: 49rem) {
  .panel-2col .panel-col-first {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 57.3509785009%;
  }
  .panel-2col .panel-col-first:last-child {
    margin-right: 0;
  }
}
.panel-2col .panel-col-last {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
}
.panel-2col .panel-col-last:last-child {
  margin-right: 0;
}
@media screen and (min-width: 49rem) {
  .panel-2col .panel-col-last {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%;
    margin-right: 0;
  }
  .panel-2col .panel-col-last:last-child {
    margin-right: 0;
  }
  .panel-2col .panel-col-last .inside {
    margin-left: 0.5em;
  }
}
.panel-2col .panel-col-last .inside {
  margin-left: 0;
}

/* @file
 * Justice Network component styles.
 */
/* @file
 * Re-usable style elements for use throughout the Justice Network site.
  */
.pane-bundle-video div div {
  position: relative;
}
.pane-bundle-video div div:before {
  display: block;
  content: "";
  padding-top: 74.21875%;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-video div div:before {
    margin-bottom: -12%;
  }
}
.pane-bundle-video div div > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 74.21875%;
}

.webform-component--required em {
  color: red;
}

a:hover {
  text-decoration: underline;
}

ul.pager-load-more {
  margin-top: 2em;
}
ul.pager-load-more a {
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: #f27200;
  border: none;
  padding: 0.8em 1.2em;
  text-decoration: none;
}
ul.pager-load-more a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 49rem) {
  ul.pager-load-more a {
    font-size: 0.7em;
  }
}

/* @file
 * Styles for the Find Quest page, views, and blocks.
 */
.page-find-quest h1.title {
  font-size: 2em;
}
.page-find-quest .views-exposed-form .views-widget-filter-field_geofield_distance {
  padding: 0.5em 0 0 0;
}
.page-find-quest .views-exposed-form .views-widget-filter-field_geofield_distance .geofield-proximity-origin {
  width: 15em;
}
.page-find-quest .views-exposed-form .views-exposed-widget .form-submit {
  margin-top: 1.9em;
}
.page-find-quest .view-display-id-attachment_1 {
  border-top: 1px solid #a0a0a0;
  border-bottom: 1px solid #a0a0a0;
  margin: 3em 0;
}
.page-find-quest .view-display-id-attachment_1 .views-field-title {
  font-size: 1.2em;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 1em;
}
.page-find-quest .view-display-id-attachment_1 p {
  margin: 0.5em 0;
}
.page-find-quest .view-display-id-attachment_1 .view-empty {
  margin: 3em 0;
}
.page-find-quest .openlayers-views-map {
  margin: 3em 0;
  width: 100%;
  height: 18em;
}
@media screen and (min-width: 23rem) {
  .page-find-quest .openlayers-views-map {
    height: 23em;
  }
}
@media screen and (min-width: 35rem) {
  .page-find-quest .openlayers-views-map {
    height: 29em;
  }
}
@media screen and (min-width: 49rem) {
  .page-find-quest .openlayers-views-map {
    height: 33em;
  }
}
@media screen and (min-width: 49rem) {
  .page-find-quest .openlayers-views-map {
    height: 36em;
  }
}
.page-find-quest .openlayers-views-map .openlayers-container {
  width: 100%;
  height: 100%;
}
.page-find-quest .openlayers-views-map .openlayers-container .openlayers-map {
  width: 100%;
  height: 100%;
}

.pane-jn-find-justice-find-justice {
  text-align: center;
  min-height: 16em;
  padding: 1em;
  margin: 0 -1rem;
  background-image: url(../images/bg-gray-map-crop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media screen and (min-width: 23rem) {
  .pane-jn-find-justice-find-justice {
    margin: 0 -2rem;
  }
}
@media screen and (min-width: 49rem) {
  .pane-jn-find-justice-find-justice {
    min-height: 14em;
  }
}
.pane-jn-find-justice-find-justice h2.pane-title {
  font-size: 1.5em;
  margin: 0;
  padding: 2em 0 0;
}
@media screen and (min-width: 49rem) {
  .pane-jn-find-justice-find-justice h2.pane-title {
    font-size: 2.4em;
    padding-top: 1em;
  }
}
.pane-jn-find-justice-find-justice .form-item-search {
  display: inline-block;
}
.pane-jn-find-justice-find-justice .form-item-search label {
  display: none;
}
.pane-jn-find-justice-find-justice .form-item-search input {
  background-color: transparent;
  font-size: 1.3em;
  width: 13em;
  height: 2.5rem;
  border-right: 2.5rem solid white;
}
@media screen and (min-width: 49rem) {
  .pane-jn-find-justice-find-justice .form-item-search input {
    font-size: 1em;
    width: 20em;
  }
}
.pane-jn-find-justice-find-justice .form-submit {
  display: inline-block;
  height: 1.9rem;
  position: relative;
  left: -6px;
  margin-left: -2.2rem;
  color: #f27200;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  text-indent: -9999px;
  width: 2rem;
  background-image: url(../images/location-icon-orange.png);
  background-repeat: no-repeat;
  background-position: center;
}
@media screen and (min-width: 49rem) {
  .pane-jn-find-justice-find-justice .form-submit {
    left: -7px;
  }
}
@media screen and (min-width: 73rem) {
  .pane-jn-find-justice-find-justice .form-submit {
    left: -8px;
  }
}

/* @file
 * Styles for footer-bottom on Justice Network site.
 */
.footer-bottom {
  width: 100%;
}
.footer-bottom a {
  color: inherit;
  font-weight: inherit;
  text-decoration: none;
}
.footer-bottom a:hover {
  color: white;
}
.footer-bottom .container-wrapper {
  background-color: #dddcdc;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.footer-bottom .container-wrapper .content {
  font-size: 0.35rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #434343;
}
@media screen and (min-width: 23rem) {
  .footer-bottom .container-wrapper .content {
    font-size: 0.4rem;
    letter-spacing: 0.05em;
  }
}
@media screen and (min-width: 49rem) {
  .footer-bottom .container-wrapper .content {
    font-size: 0.6rem;
    font-weight: 200;
    letter-spacing: 0.1em;
  }
}
.footer-bottom .container-wrapper .content .field {
  display: inline-block;
}
.footer-bottom .container-wrapper .content .field .field-items {
  display: inline-block;
}
.footer-bottom .container-wrapper .content .field .field-items .field-item {
  display: inline-block;
}
.footer-bottom .container-wrapper .content .field-name-field-linkinfo-links .field-item {
  margin-right: 1.1em;
}
.footer-bottom .container-wrapper .content .field-name-field-linkinfo-links .field-item::after {
  content: "|";
}
.footer-bottom .container-wrapper .content .field-name-field-linkinfo-links .field-item a {
  margin-right: 1.2em;
}

/* @file
 * Styles for the Footer Middle section of the Justice Network site.
 */
.footer-middle {
  background-color: #ebedea;
}
.footer-middle .content {
  width: 100%;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content {
    display: table;
  }
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav {
    display: table-cell;
    width: 50%;
  }
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav .footer-nav-columns {
    display: table;
    width: 100%;
  }
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav .footer-nav-columns .block {
    display: table-cell;
    width: 33.3%;
    padding-right: 0.5em;
  }
}
.footer-middle .content .footer-nav .footer-nav-columns .block .title {
  display: none;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav .footer-nav-columns .block .title {
    display: block;
    font-family: Poppins, sans-serif;
    font-size: 0.725em;
    letter-spacing: 0;
    color: black;
    text-transform: uppercase;
  }
}
.footer-middle .content .footer-nav .footer-nav-columns .block ul {
  padding: 0;
  margin: 0;
}
.footer-middle .content .footer-nav .footer-nav-columns .block ul li {
  list-style: none;
  margin: 0 -2.5em;
  padding: 1em 2.5em 0.7em;
  border-bottom: 1px solid #dddcdc;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav .footer-nav-columns .block ul li {
    margin: 0;
    padding: 0;
    border-bottom: none;
  }
}
.footer-middle .content .footer-nav .footer-nav-columns .block ul li a {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 200;
  color: #f27200;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-nav .footer-nav-columns .block ul li a {
    font-size: 0.6em;
  }
}
.footer-middle .content .footer-nav .footer-nav-columns .block ul li a:hover {
  text-decoration: underline;
}
.footer-middle .content .footer-desc {
  font-family: Poppins, sans-serif;
  color: black;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0;
  font-weight: 700;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-desc {
    display: table-cell;
    width: 50%;
  }
}
.footer-middle .content .footer-desc .block {
  padding: 1rem 0;
}
@media screen and (min-width: 62rem) {
  .footer-middle .content .footer-desc .block {
    padding: 0 1.5rem 0 4rem;
  }
}
@media screen and (min-width: 84rem) {
  .footer-middle .content .footer-desc .block {
    padding: 0 3.5rem 0 6rem;
  }
}

/* @file
 * Justice Network header styles
 */
#header a.menu-toggle {
  display: none;
}
@media screen and (max-width: 49rem) {
  #header a.menu-toggle {
    display: block;
    float: right;
    background-image: url(../images/nav-hamburger.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2em;
    padding: 1.25em;
    margin-top: 0.3em;
    cursor: pointer;
  }
  #header a.menu-toggle:hover {
    opacity: 0.7;
  }
}

#header-logo {
  display: block;
  margin: 0;
}
@media screen and (min-width: 49rem) {
  #header-logo {
    display: table-cell;
    vertical-align: middle;
  }
}
#header-logo a:hover img {
  opacity: 0.85;
}
#header-logo img {
  height: auto;
  max-width: 110px;
}
@media screen and (min-width: 49rem) {
  #header-logo img {
    max-width: 100%;
  }
}
#header-logo img.logo-white {
  display: none;
}
#header-logo img.logo-default {
  display: block;
}

#region-header-wrapper {
  display: table-cell;
}

#header-wrapper {
  width: 100%;
  background-image: linear-gradient(#2e2c2d, rgba(46, 44, 45, 0));
  padding: 1em 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
#header-wrapper.mobile-menu-open {
  background-color: #f27200;
  background-image: none;
  padding-top: 1.4rem;
  position: absolute;
  top: -1.1em;
  left: 0;
}
#header-wrapper.mobile-menu-open #region-header-wrapper {
  display: block;
}
#header-wrapper.mobile-menu-open #header-logo img.logo-default {
  display: none;
}
#header-wrapper.mobile-menu-open #header-logo img.logo-white {
  display: block;
  width: 13em;
}
#header-wrapper.mobile-menu-open a.menu-toggle {
  background-image: url(../images/nav-mobile-close.png);
}

@media screen and (max-width: 49rem) {
  #region-header-wrapper {
    display: none;
  }
}
#region-header {
  display: table;
  width: 100%;
}
@media screen and (max-width: 49rem) {
  #region-header {
    display: block;
  }
}
#region-header .nav-header {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
}
@media screen and (max-width: 49rem) {
  #region-header .nav-header {
    display: block;
  }
}
#region-header .nav-header ul.menu {
  text-align: inherit;
  padding: 0;
  margin: 0;
}
#region-header .nav-header ul.menu li {
  color: white;
  display: inline;
  list-style: none;
  margin: 0.5em;
}
#region-header .nav-header ul.menu li a {
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 0.7em;
  font-weight: 200;
}
#region-header .nav-header ul.menu li a:hover {
  color: #a1a1a1;
}
@media screen and (max-width: 49rem) {
  #region-header .nav-header ul.menu {
    text-align: left;
    margin-top: 2.5em;
  }
  #region-header .nav-header ul.menu li {
    display: block;
    padding: 1.25em 0;
    margin: 0;
    border-bottom: 1px solid #f27200;
  }
  #region-header .nav-header ul.menu li a {
    font-size: 1.25em;
  }
}
#region-header .icon-list-header {
  display: table-cell;
  vertical-align: middle;
  width: 8em;
}
@media screen and (max-width: 49rem) {
  #region-header .icon-list-header {
    display: block;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #f27200;
  }
}
#region-header .icon-list-header .field-name-field-linked-icons > .field-items {
  display: table-row;
}
#region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item {
  display: table-cell;
  vertical-align: middle;
  padding: 0.5em;
}
#region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item svg {
  stroke: white;
  fill: white;
  width: 1em;
  height: 1em;
}
#region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item svg:hover {
  stroke: #f27200;
  fill: #f27200;
}
@media screen and (max-width: 49rem) {
  #region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item {
    padding: 1em 2.5em 0.75em 0;
  }
  #region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item svg {
    width: 1.25em;
    height: 1.25em;
  }
  #region-header .icon-list-header .field-name-field-linked-icons > .field-items > .field-item svg:hover {
    stroke: #a1a1a1;
    fill: #a1a1a1;
  }
}
#region-header .header-find-justice {
  display: table-cell;
  vertical-align: middle;
  text-align: right;
  width: 19em;
}
#region-header .header-find-justice a.watch-stream {
  font-size: 0.65em;
  font-weight: 200;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.8em 1.5em 0.4em 1.5em;
  margin-right: 1.5em;
  border: 1px solid white;
  background-color: white;
  color: black;
}
#region-header .header-find-justice a.watch-stream:hover {
  border-color: #f27200;
  background-color: #f27200;
  color: white;
}
#region-header .header-find-justice a.find-justice {
  font-size: 0.65em;
  font-weight: 200;
  text-decoration: none;
  text-transform: uppercase;
  display: inline-block;
  padding: 0.8em 3em 0.4em 1.5em;
  border: 1px solid white;
  background-image: url(../images/location-icon.png);
  background-position: right 1em center;
  background-repeat: no-repeat;
}
#region-header .header-find-justice a.find-justice:hover {
  border-color: #f27200;
  background-color: #f27200;
}
@media screen and (max-width: 49rem) {
  #region-header .header-find-justice {
    display: block;
    width: 17em;
    padding-bottom: 1em;
  }
  #region-header .header-find-justice a.watch-stream {
    font-size: 1.25em;
    display: block;
    margin: 3em 0 1em;
    padding: 1em;
    text-align: center;
  }
  #region-header .header-find-justice a.find-justice {
    font-size: 1.25em;
    padding: 1.25em 4em 1em 1.5em;
    margin: 1em 0;
    display: block;
  }
  #region-header .header-find-justice a.find-justice:hover {
    border-color: #a1a1a1;
    background-color: #a1a1a1;
  }
}

/* @file
 * Justice Network hero styles.
 */
.hero-container {
  position: relative;
  min-height: 5rem;
  overflow: hidden;
}
.hero-container h1 {
  font-size: 32px;
}
@media screen and (min-width: 49rem) {
  .hero-container h1 {
    font-size: 56px;
  }
}
@media screen and (min-width: 62rem) {
  .hero-container h1 {
    font-size: 72px;
  }
}
@media screen and (min-width: 73rem) {
  .hero-container h1 {
    font-size: 88px;
  }
}
@media screen and (min-width: 84rem) {
  .hero-container h1 {
    font-size: 102px;
  }
}
.hero-container .field-type-image .field-item {
  text-align: center;
  position: relative;
  height: 15rem;
}
@media screen and (min-width: 28rem) {
  .hero-container .field-type-image .field-item {
    height: 23rem;
  }
}
@media screen and (min-width: 49rem) {
  .hero-container .field-type-image .field-item {
    height: 40.75rem;
  }
}
.hero-container .field-type-image .field-item img {
  display: block;
  position: absolute;
  left: -9999px;
  right: -9999px;
  margin: 0 auto;
  height: 13.5rem;
  width: 100%;
  object-fit: cover;
}
@media screen and (min-width: 28rem) {
  .hero-container .field-type-image .field-item img {
    height: 25rem;
  }
}
@media screen and (min-width: 49rem) {
  .hero-container .field-type-image .field-item img {
    height: 40.75rem;
  }
}
.hero-container .hero-gradient {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  width: 100%;
  height: 8rem;
  background-image: linear-gradient(transparent 0, rgba(88, 89, 91, 0.6) 50%, #58595b 90%);
}
@media screen and (min-width: 28rem) {
  .hero-container .hero-gradient {
    bottom: 0;
    height: 16rem;
  }
}
@media screen and (min-width: 49rem) {
  .hero-container .hero-gradient {
    height: 25rem;
  }
}
.hero-container .hero-title {
  position: absolute;
  font-weight: 100;
  left: 1rem;
  max-width: 80%;
  top: 6rem;
}
@media screen and (min-width: 23rem) {
  .hero-container .hero-title {
    left: 2rem;
  }
}
@media screen and (min-width: 28rem) {
  .hero-container .hero-title {
    top: 8rem;
  }
}
@media screen and (min-width: 49rem) {
  .hero-container .hero-title {
    top: 22rem;
  }
}
.hero-container.collapsed {
  display: none;
}
.hero-container.collapsed .hero-title {
  display: none;
}

/* @file
 * Styles for the On Now block/pane.
 */
.pane-jn-schedule-manager-on-now {
  margin: 0 -1rem;
  background-color: #dddcdc;
  color: #303030;
}
@media screen and (min-width: 23rem) {
  .pane-jn-schedule-manager-on-now {
    margin: 0 -2rem;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info {
  padding: 1rem;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info {
    display: inline-block;
    width: 34%;
    vertical-align: top;
    padding-left: 2rem;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info h3.show-title {
  font-size: 2em;
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info h4.episode-title {
  font-size: 1em;
  letter-spacing: 0.05em;
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .episode-synopsis {
  margin-bottom: 1em;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .episode-synopsis {
    font-size: 0.8em;
    letter-spacing: 0.05em;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .show-link {
  margin-bottom: 1em;
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .show-link .button {
  font-size: 1em;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .show-link .button {
    font-size: 0.7em;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .indicator {
  display: inline-block;
  font-size: 0.7em;
  font-family: Poppins, sans-serif;
  font-weight: 300;
  letter-spacing: 0;
  color: black;
  text-transform: uppercase;
  background-color: #f7b500;
  min-width: 5em;
  text-align: center;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .episode-info .indicator {
    padding: 0 0.8em 0 1em;
    min-width: 7em;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .show-image {
  overflow: hidden;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .show-image {
    display: inline-block;
    width: 65%;
    float: right;
  }
}
.pane-jn-schedule-manager-on-now .pane-content .episode-on-now .show-image img {
  display: block;
  width: 100%;
  height: auto;
}
@media screen and (min-width: 49rem) {
  .pane-jn-schedule-manager-on-now .pane-content .episode-on-now .show-image img {
    float: right;
    width: auto;
    height: 100%;
  }
}

/* @file
 * Style definitions for About page for Justice Network.
 */
.page-node-6 {
  background-color: white;
  color: #f7b500;
}
.page-node-6 .carousel-slide {
  color: white;
}
.page-node-6 .hero-container {
  min-height: 0;
}
.page-node-6 .hero-title {
  max-width: 30rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .hero-title {
    top: 12rem;
  }
}
.page-node-6 h2 {
  color: #f27200;
}
.page-node-6 .hero-title {
  color: white;
  text-align: left;
}
.page-node-6 #page {
  text-align: center;
}
.page-node-6 #content {
  padding: 0;
}
.page-node-6 #footer {
  color: white;
  text-align: left;
}
.page-node-6 .panel-pane {
  overflow: hidden;
  padding: 0 2.3rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 #page {
    text-align: left;
  }
}
.page-node-6 #content img {
  height: auto;
  max-width: 100%;
}
.page-node-6 .see-more-arrow {
  display: none;
}
.page-node-6 .pane-title {
  font-weight: 100;
}
.page-node-6 .pane-intro-text {
  margin: auto;
  max-width: 49rem;
  text-transform: uppercase;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-intro-text {
    text-align: center;
  }
}
.page-node-6 .pane-intro-text .pane-title {
  font-size: 1.2rem;
  margin: 2.5rem 0 1rem;
}
.page-node-6 .pane-intro-text .pane-content {
  background-color: transparent;
  color: inherit;
  font-size: 0.7rem;
  line-height: 1.2rem;
  padding: 0 1rem 1.5rem;
  text-align: center;
}
.page-node-6 .pane-featured-bio {
  background-color: #58595b;
  color: white;
  overflow: hidden;
  text-transform: uppercase;
}
.page-node-6 .pane-featured-bio .pane-content {
  border-bottom: 1px solid white;
  overflow: hidden;
  padding-bottom: 1rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-featured-bio .pane-content {
    min-height: 950px;
    padding: 0 0 0.5rem;
    position: relative;
  }
}
.page-node-6 .pane-featured-bio .field-name-field-name {
  color: white;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 2.2rem;
  padding-top: 2rem;
  margin: 0;
  font-size: 1.2rem;
  margin: 0 0 1rem;
  line-height: 1.4rem;
}
.page-node-6 .pane-featured-bio .field-name-field-preamble-text {
  background-color: transparent;
  color: inherit;
  font-size: 0.7rem;
  line-height: 1.2rem;
  padding: 0 1rem 1rem;
  text-align: center;
}
.page-node-6 .pane-featured-bio .field-name-field-preamble-text p {
  margin: 0 auto;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-featured-bio .field-name-field-preamble-text {
    line-height: 1.35rem;
    padding: 0;
    text-align: left;
    width: 40%;
  }
}
.page-node-6 .pane-featured-bio .field-name-field-bio {
  background-color: white;
  color: black;
  font-size: 0.6rem;
  line-height: 1rem;
  padding: 1rem;
  text-align: left;
  clear: both;
  text-transform: none;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-featured-bio .field-name-field-bio {
    float: left;
    margin-top: 1rem;
    padding: 2.7rem 4rem;
    position: relative;
    width: 53%;
    z-index: 2;
  }
}
.page-node-6 .pane-featured-bio .field-name-field-bio-image {
  float: right;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-featured-bio .field-name-field-bio-image {
    position: absolute;
    right: 0;
    top: 2rem;
    width: 51%;
    z-index: 1;
  }
}
.page-node-6 .pane-featured-bio .field-name-field-bio-image img {
  float: right;
  width: 100%;
}
.page-node-6 .pane-bio-list {
  background-color: #58595b;
  color: white;
}
.page-node-6 .pane-bio-list .pane-title {
  color: white;
  font-size: 1.3rem;
  font-weight: 100;
  line-height: 1.3rem;
  padding-top: 1rem;
  margin: 0;
  margin-bottom: 1rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .pane-title {
    padding-top: 3rem;
  }
}
.page-node-6 .pane-bio-list .field-name-field-bios > .field-items > .field-item {
  border-bottom: 1px solid white;
  margin-bottom: 1rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .field-name-field-bios > .field-items > .field-item {
    margin-bottom: 0;
  }
}
.page-node-6 .pane-bio-list .field-name-field-bios > .field-items > .field-item:last-child {
  border-bottom: none;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio {
    padding: 1.5rem 0 1rem;
  }
}
.page-node-6 .pane-bio-list .entity-bio > h2 {
  display: none;
}
.page-node-6 .pane-bio-list .entity-bio .bio-image-text {
  display: inline-block;
  position: relative;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio .bio-image-text {
    float: left;
    width: 32%;
  }
}
.page-node-6 .pane-bio-list .entity-bio .bio-text-wrap {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
}
.page-node-6 .pane-bio-list .entity-bio .field-name-field-name {
  color: #f27200;
  font-family: Poppins, sans-serif;
  font-size: 0.7rem;
  font-weight: 100;
  line-height: 0.7rem;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 0.2rem;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio .field-name-field-name {
    clear: left;
  }
}
.page-node-6 .pane-bio-list .entity-bio .field-name-field-professional-title {
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 0.7rem;
  font-weight: 100;
  line-height: 0.7rem;
  text-align: left;
  text-transform: uppercase;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio .field-name-field-professional-title {
    clear: none;
  }
}
.page-node-6 .pane-bio-list .entity-bio .field-name-field-bio {
  text-align: left;
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio .field-name-field-bio {
    float: right;
    padding-left: 1rem;
    width: 68%;
  }
}
@media screen and (min-width: 49rem) {
  .page-node-6 .pane-bio-list .entity-bio .field-name-field-bio p {
    margin: 0 0 2rem;
  }
}
/* @file
 * Style definitions for a Media page and News content pages.
 */
body.node-type-news h1 {
  font-size: 20px;
}
@media screen and (min-width: 49rem) {
  body.node-type-news h1 {
    margin-top: 3.5rem;
  }
}
@media screen and (min-width: 62rem) {
  body.node-type-news h1 {
    font-size: 40px;
    max-width: 80%;
  }
}
@media screen and (min-width: 49rem) {
  body.node-type-news .section-main {
    float: right;
    margin-bottom: 3rem;
    width: 77.5%;
  }
}
@media screen and (min-width: 62rem) {
  body.node-type-news .section-main > * {
    max-width: 80%;
  }
}
body.node-type-news .section-main img {
  height: auto;
  max-width: 100%;
}
body.node-type-news .section-main .video {
  position: relative;
}
body.node-type-news .section-main .video:before {
  display: block;
  content: "";
  padding-top: 74.21875%;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  body.node-type-news .section-main .video:before {
    margin-bottom: -12%;
  }
}
body.node-type-news .section-main .video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 74.21875%;
}
body.node-type-news .section-cta {
  margin-bottom: 3em;
}
@media screen and (min-width: 49rem) {
  body.node-type-news .section-cta {
    float: left;
    width: 22.5%;
  }
}

@media screen and (min-width: 49rem) {
  body.page-node-44 #content {
    padding: 0 1rem;
  }
}
@media screen and (min-width: 62rem) {
  body.page-node-44 #content {
    padding: 0 2rem;
  }
}
@media screen and (min-width: 84rem) {
  body.page-node-44 #content {
    padding: 2rem 4rem 0;
  }
}
body.page-node-44 .panel-2col {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
}
body.page-node-44 .panel-2col::after {
  clear: both;
  content: "";
  display: table;
}
body.page-node-44 .panel-2col .panel-col-first {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
}
body.page-node-44 .panel-2col .panel-col-first:last-child {
  margin-right: 0;
}
@media screen and (min-width: 49rem) {
  body.page-node-44 .panel-2col .panel-col-first {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 57.3509785009%;
  }
  body.page-node-44 .panel-2col .panel-col-first:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62rem) {
  body.page-node-44 .panel-2col .panel-col-first {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 65.8807828007%;
  }
  body.page-node-44 .panel-2col .panel-col-first:last-child {
    margin-right: 0;
  }
}
body.page-node-44 .panel-2col .panel-col-last {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
}
body.page-node-44 .panel-2col .panel-col-last:last-child {
  margin-right: 0;
}
@media screen and (min-width: 49rem) {
  body.page-node-44 .panel-2col .panel-col-last {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%;
    padding-top: 3.2rem;
  }
  body.page-node-44 .panel-2col .panel-col-last:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 62rem) {
  body.page-node-44 .panel-2col .panel-col-last {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
    padding-top: 5.35rem;
  }
  body.page-node-44 .panel-2col .panel-col-last:last-child {
    margin-right: 0;
  }
}
body.page-node-44 .panel-2col .panel-col-last .panel-separator {
  margin-bottom: 3em;
}
body.page-node-44 .panel-2col .panel-col-last h2.pane-title {
  font-family: "Helvetica Neue";
  font-size: 18px;
  font-weight: 700;
}
@media screen and (min-width: 49rem) {
  body.page-node-44 .panel-2col .panel-col-last h2.pane-title {
    font-size: 1.1em;
  }
}
body.page-node-44 .panel-2col .panel-col-last .document-link {
  margin-bottom: 0.5em;
}
body.page-node-44 .panel-2col .panel-col-last .document-link a {
  color: white;
  font-size: 0.9em;
  text-decoration: underline;
}
body.page-node-44 .panel-2col .panel-col-last .document-link a:hover {
  color: #ffffff;
  text-decoration: none;
}
body.page-node-44 .panel-2col .panel-col-last .node-webform header {
  display: none;
}
body.page-node-44 .panel-2col .panel-col-last .node-webform .webform-client-form input.form-email {
  width: 100%;
}
body.page-node-44 .view-recent-news .view-content {
  padding-bottom: 1em;
  margin-bottom: 1em;
  border-bottom: 1px solid #d0d0d0;
}
body.page-node-44 .view-recent-news .views-row {
  font-size: 0.9em;
  padding-top: 1em;
  margin-top: 1em;
  border-top: 1px solid #d0d0d0;
}
body.page-node-44 .view-recent-news .views-row .views-field-title a {
  font-size: 1.1em;
  text-decoration: none;
}
body.page-node-44 .view-recent-news .views-row .views-field-title a:hover {
  text-decoration: underline;
}
body.page-node-44 .view-recent-news .views-row .views-field-view-node a {
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: #f27200;
  border: none;
  padding: 0.8em 1.2em;
  text-decoration: none;
  margin-bottom: 1em;
}
body.page-node-44 .view-recent-news .views-row .views-field-view-node a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 49rem) {
  body.page-node-44 .view-recent-news .views-row .views-field-view-node a {
    font-size: 0.7em;
  }
}

/* @file
 * Style definitions for Partners page for Justice Network.
 */
.page-node-5 {
  background-color: white;
  color: #f7b500;
}
.page-node-5 .carousel-slide {
  color: white;
}
.page-node-5 .hero-container {
  min-height: 0;
}
.page-node-5 .hero-title {
  max-width: 30rem;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .hero-title {
    top: 12rem;
  }
}
.page-node-5 h2 {
  color: #f27200;
}
.page-node-5 .hero-title {
  color: white;
  text-align: left;
}
.page-node-5 #page {
  text-align: center;
}
.page-node-5 #content {
  padding: 0;
}
.page-node-5 #footer {
  color: white;
  text-align: left;
}
.page-node-5 .panel-pane {
  overflow: hidden;
  padding: 0 1rem;
}
.page-node-5 .pane-title {
  font-weight: 100;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-title {
    margin-top: 2rem;
  }
}
.page-node-5 .pane-bundle-icon-text-list {
  margin: 0 auto;
  max-width: 46rem;
  overflow: hidden;
}
.page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items > .field-item {
  font-weight: 600;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items > .field-item {
    width: 33.3333333333%;
  }
  .page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items > .field-item .field-type-image,
.page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items > .field-item .field-type-text-long {
    display: block;
  }
  .page-node-5 .pane-bundle-icon-text-list .field-type-entityreference > .field-items > .field-item .field-type-text-long {
    margin: 0 auto 2rem;
    width: 80%;
  }
}
.page-node-5 .pane-bundle-video {
  margin: 1rem auto 0;
  max-width: 46rem;
  position: relative;
  z-index: 1;
}
.page-node-5 .pane-bundle-email {
  background-color: #f7b500;
  color: white;
  position: relative;
  padding: 1rem;
  text-transform: uppercase;
  z-index: 2;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-bundle-email {
    padding-top: 2.5rem;
  }
  .page-node-5 .pane-bundle-email p {
    font-size: 0.8rem;
  }
}
.page-node-5 .pane-bundle-email h3 {
  font-size: 1.4rem;
  font-weight: 100;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-bundle-email h3 {
    display: inline;
  }
  .page-node-5 .pane-bundle-email h3 + p {
    display: inline;
  }
}
.page-node-5 .pane-bundle-email a {
  text-decoration: none;
  word-wrap: break-word;
}
.page-node-5 .pane-bundle-email .email-info {
  color: #f27200;
  font-size: 1.4rem;
  line-height: 1.5rem;
}
.page-node-5 .pane-webform {
  background: #f27200;
  color: white;
  display: inline-block;
  padding: 2rem 0 1.5rem;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform h2 {
    margin-bottom: 0;
  }
}
.page-node-5 .pane-webform a {
  pointer-events: none;
  text-decoration: none;
}
.page-node-5 .pane-webform form {
  margin: 0 auto;
  max-width: 35rem;
  padding: 0 1rem;
}
.page-node-5 .pane-webform input[type=text], .page-node-5 .pane-webform input[type=password], .page-node-5 .pane-webform input[type=number], .page-node-5 .pane-webform input[type=email], .page-node-5 .pane-webform textarea {
  background-color: #272727;
  margin-bottom: 1rem;
  width: 100%;
}
.page-node-5 .pane-webform label {
  font-weight: 100;
  text-align: left;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform label {
    font-size: 0.75rem;
  }
}
.page-node-5 .pane-webform .form-item, .page-node-5 .pane-webform .form-actions {
  background-color: #272727;
  clear: both;
  display: inline-block;
  margin: -1px 0;
  padding: 1px 1rem;
  width: 100%;
}
.page-node-5 .pane-webform .form-item.webform-component--first-name {
  padding-top: 1rem;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .form-item.webform-component--first-name {
    clear: none;
    float: left;
    width: 50%;
  }
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .webform-component--last-name {
    clear: none;
    float: left;
    padding-top: 1rem;
    width: 50%;
  }
}
.page-node-5 .pane-webform .form-actions {
  padding-bottom: 2rem;
}
.page-node-5 .pane-webform .form-actions input {
  font-size: 1.1em;
  font-weight: 100;
  padding: 0.3rem 1rem;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .form-actions input {
    font-size: 0.75rem;
  }
}
.page-node-5 .pane-webform .webform-component-markup {
  text-align: left;
}
.page-node-5 .pane-webform .webform-component-markup:first-of-type {
  background: transparent;
  margin: 0 auto;
  max-width: 26rem;
  text-align: center;
  text-transform: uppercase;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .webform-component-markup:first-of-type {
    font-size: 0.8rem;
    line-height: 1rem;
  }
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .webform-component-markup.webform-component--required {
    float: left;
  }
}
.page-node-5 .pane-webform .webform-component-markup.webform-component--required p {
  margin: 0 0 1.5rem;
}
@media screen and (min-width: 49rem) {
  .page-node-5 .pane-webform .webform-component-markup.webform-component--required p {
    font-size: 0.7rem;
  }
}
.page-node-5 .statistic .text .field-item {
  color: white;
}

/**
 * Styles for the Photo List fieldable panels pane.
 */
.pane-bundle-photo-list h2.pane-title {
  margin-top: 1.5em;
}
.pane-bundle-photo-list .pane-content .fieldable-panels-pane {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
}
.pane-bundle-photo-list .pane-content .fieldable-panels-pane::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
  }
  .pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 49rem) {
  .pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list .entity-photo .field-name-field-image {
    text-align: center;
  }
}
.pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list .entity-photo .field-name-field-image img {
  max-width: 100%;
  height: auto;
}
.pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list .entity-photo .field-name-field-image-caption {
  margin-bottom: 1em;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-photo-list .pane-content .fieldable-panels-pane .field-name-field-photo-list .entity-photo .field-name-field-image-caption {
    text-align: center;
    margin-bottom: 0;
  }
}

/* @file
 * Style definitions for a Show profile page.
 */
@media screen and (min-width: 49rem) {
  .page-node.node-type-show .content {
    max-width: 79rem;
    margin-left: auto;
    margin-right: auto;
  }
  .page-node.node-type-show .content::after {
    clear: both;
    content: "";
    display: table;
  }
  .page-node.node-type-show .content .section-cta {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 23.2317613015%;
  }
  .page-node.node-type-show .content .section-cta:last-child {
    margin-right: 0;
  }
  .page-node.node-type-show .content .section-main {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 74.4105871005%;
  }
  .page-node.node-type-show .content .section-main:last-child {
    margin-right: 0;
  }
}
.page-node.node-type-show .content .video {
  position: relative;
}
.page-node.node-type-show .content .video:before {
  display: block;
  content: "";
  padding-top: 74.21875%;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .page-node.node-type-show .content .video:before {
    margin-bottom: -12%;
  }
}
.page-node.node-type-show .content .video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 74.21875%;
}

#social-share {
  width: 12em;
}
@media screen and (min-width: 49rem) {
  #social-share {
    margin-top: 2em;
  }
}
@media screen and (min-width: 73rem) {
  #social-share {
    margin-top: 1.8rem;
  }
}

#share-dialog {
  position: relative;
  margin: 0 auto;
  padding: 1rem;
  text-align: center;
  background-color: white;
  color: #0b0b0b;
  width: 90%;
}
@media screen and (min-width: 49rem) {
  #share-dialog {
    font-size: 0.8em;
  }
}
@media screen and (min-width: 26rem) {
  #share-dialog {
    width: 20rem;
  }
}
#share-dialog .mfp-close {
  font-size: 0.8em;
}
#share-dialog .mfp-close:hover {
  cursor: pointer;
}
#share-dialog .share-title {
  color: #f27200;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
#share-dialog .a2a_kit {
  display: inline-block;
}

/**
 * Shows page tweaks
 */
.page-node-41 .hero-container {
  min-height: 0;
}

/* @file
 * Styling for RSS link in Views output.
 */
.pane-rss-link {
  letter-spacing: 0.05em;
  float: right;
  clear: right;
  margin-bottom: 0.7em;
}
.pane-rss-link p {
  margin: 0.1em 0 0;
}
.pane-rss-link p a {
  text-decoration: none;
  color: #f7b500;
}
.pane-rss-link p a:visited {
  color: #f7b500;
}
.pane-rss-link p a:hover {
  color: #a1a1a1;
}
.pane-rss-link p a img {
  width: 1.3em;
  height: auto;
  margin-right: 0.3em;
  position: relative;
  top: 0.3em;
}

/**
 * Show List Panel and Pane Styling
 *
 * Start off with all styles here...I'd like to break up after I get a grip on what I have, structure wise.
 *
 * I don't like the "show-page" class because it requires the panelizer settings to include that configuration.
 *
 */
.pane-bundle-show-list {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2em;
  padding-bottom: 2em;
  border-bottom: 1px solid #aaaaaa;
}
.pane-bundle-show-list::after {
  clear: both;
  content: "";
  display: table;
}
body.front .pane-bundle-show-list {
  background-color: #ffffff;
  border-bottom: none;
  margin: 0 -2rem;
  padding: 1em 4em 2em;
  max-width: unset;
}
body.front .pane-bundle-show-list h2 {
  color: #000000;
}
.pane-bundle-show-list .pane-content .field-items {
  overflow: auto;
}
.pane-bundle-show-list .pane-content .field-items .field-item {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-bottom: 1em;
}
.pane-bundle-show-list .pane-content .field-items .field-item:last-child {
  margin-right: 0;
}
.pane-bundle-show-list .pane-content .field-items .field-item:nth-child(1n) {
  margin-right: 0;
}
.pane-bundle-show-list .pane-content .field-items .field-item:nth-child(1n+1) {
  clear: left;
}
@media screen and (min-width: 26rem) {
  .pane-bundle-show-list .pane-content .field-items .field-item {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:last-child {
    margin-right: 0;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(1n) {
    margin-right: 2.3576515979%;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(1n+1) {
    clear: none;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(2n) {
    margin-right: 0;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 49rem) {
  .pane-bundle-show-list .pane-content .field-items .field-item {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:last-child {
    margin-right: 0;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(2n) {
    margin-right: 2.3576515979%;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(2n+1) {
    clear: none;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(3n) {
    margin-right: 0;
  }
  .pane-bundle-show-list .pane-content .field-items .field-item:nth-child(3n+1) {
    clear: left;
  }
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link {
  display: block;
  text-decoration: none;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .field-name-field-image-grid img {
  display: block;
  width: 100%;
  height: auto;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay {
  padding: 0.5em 1.75em;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
  font-size: 0.65em;
  font-weight: normal;
  line-height: 1.25em;
  background-color: #000000;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay .overlay-inner {
  position: relative;
  height: 85%;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay .overlay-inner .field-name-field-show-airtime, .pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay .overlay-inner .info {
  position: absolute;
  bottom: 0;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay .overlay-inner .field-name-field-show-airtime {
  color: #2b81af;
  font-style: italic;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link .thumbnail .thumbnail-overlay .overlay-inner .info {
  right: 0;
  background-color: #ffffff;
  color: #000000;
  font-size: 1.2em;
  font-weight: 700;
  font-style: italic;
  padding: 9px 12px 4px;
  -webkit-border-radius: 21px;
  -moz-border-radius: 21px;
  border-radius: 21px;
}
.pane-bundle-show-list .pane-content .field-items .field-item a.show-link:hover .thumbnail .thumbnail-overlay {
  opacity: 0.8;
}
.pane-bundle-show-list .pane-content .field-name-field-shows-link {
  display: table;
  margin-left: auto;
  margin-right: auto;
}
.pane-bundle-show-list .pane-content .field-name-field-shows-link a {
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: #f27200;
  border: none;
  padding: 0.8em 1.2em;
  text-decoration: none;
}
.pane-bundle-show-list .pane-content .field-name-field-shows-link a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-show-list .pane-content .field-name-field-shows-link a {
    font-size: 0.7em;
  }
}

/**
 * Styles for the Video List fieldable panels pane.
 */
.pane-bundle-video-list h2.pane-title {
  margin-top: 1.5em;
}
.pane-bundle-video-list .field-name-field-video-list {
  margin-bottom: 2em;
}
.pane-bundle-video-list .field-name-field-video-list .entity-video .content {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
}
.pane-bundle-video-list .field-name-field-video-list .entity-video .content::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .video {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%;
  }
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .video:last-child {
    margin-right: 0;
  }
}
.pane-bundle-video-list .field-name-field-video-list .entity-video .content .video iframe {
  max-width: 100%;
  width: 300px;
  height: 150px;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .video iframe {
    width: 400px;
    height: 200px;
  }
}
@media screen and (min-width: 62rem) {
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .video iframe {
    width: 500px;
    height: 250px;
  }
}
.pane-bundle-video-list .field-name-field-video-list .entity-video .content .field-name-field-video-embed {
  max-width: 100%;
  font-size: 0.8em;
}
@media screen and (min-width: 23rem) {
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .field-name-field-video-embed {
    font-size: inherit;
  }
}
@media screen and (min-width: 49rem) {
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .field-name-field-video-embed {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 57.3509785009%;
  }
  .pane-bundle-video-list .field-name-field-video-list .entity-video .content .field-name-field-video-embed:last-child {
    margin-right: 0;
  }
}

/* @file
 * Styles for webform panes.
 */
.pane-webform input {
  max-width: 100%;
}

.pane-webform-contact {
  padding: 1em;
  background-color: black;
}
.pane-webform-contact .webform-component input, .pane-webform-contact .webform-component textarea {
  background-color: transparent;
}

.pane-contact-title h1 {
  margin-top: 0;
}

/* Styles for the magnific popup alert modal */
.popup-alert {
  padding: 1em 2em 2em;
  background-color: black;
  border: 1px solid white;
  width: 80%;
  margin-left: 10%;
}
@media screen and (max-width: 54rem) {
  .popup-alert {
    width: 50%;
    margin-left: 25%;
  }
}
.popup-alert .dismiss {
  padding: 0 1em;
  background-color: #2f292b;
}

/* @file
 * Styles for the Schedule page.
 */
.page-schedule #content {
  padding: 0;
}

.schedule-browser header {
  padding: 0 1rem;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .date-container {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 53.0860763509%;
  }
  .schedule-browser .date-container:last-child {
    margin-right: 0;
  }
}
.schedule-browser h1.day-of-week {
  margin-bottom: 0.2em;
  font-size: 12vw;
}
@media screen and (min-width: 26rem) {
  .schedule-browser h1.day-of-week {
    font-size: 2.8rem;
  }
}
@media screen and (min-width: 49rem) {
  .schedule-browser h1.day-of-week {
    font-size: 4.2rem;
  }
}
.schedule-browser p.date {
  margin-top: 0;
  font-size: 1.3em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
.schedule-browser p.date .ordinal {
  vertical-align: super;
  font-size: 0.6em;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .controls-container {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 44.5562720511%;
    padding: 2rem 0;
  }
  .schedule-browser .controls-container:last-child {
    margin-right: 0;
  }
}
@media screen and (min-width: 784px) and (max-width: 1079px) {
  .schedule-browser .controls-container {
    float: left;
    clear: both;
    padding-top: 0;
    width: 100%;
  }
}
.schedule-browser .controls-container .date-select {
  float: left;
}
.schedule-browser .controls-container .timezone-container {
  clear: both;
  float: left;
  padding: 1em 0;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .controls-container .timezone-container {
    clear: none;
    padding: 0;
  }
}
.schedule-browser .controls-container .timezone-container button {
  border: 1px solid #a0a0a0;
  background-color: transparent;
  padding: 0.8rem;
  margin: 0 0.5rem 0 0;
}
.schedule-browser .controls-container .timezone-container button.selected {
  border-color: #f27200;
  background-color: #f27200;
}
.schedule-browser .controls-container .timezone-container button:hover {
  border-color: #f27200;
  background-color: #f27200;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .controls-container .timezone-container button {
    min-width: 55px;
  }
}
.schedule-browser .controls-container select {
  border: 1px solid #a0a0a0;
  background-color: transparent;
  padding: 0.8rem 1.25rem 0.8rem 0.8rem;
  margin: 0 0.5rem 0 0;
  max-height: 62px;
}
.schedule-browser .controls-container select.selected {
  border-color: #f27200;
  background-color: #f27200;
}
.schedule-browser .controls-container select:hover {
  border-color: #f27200;
  background-color: #f27200;
}
@-moz-document url-prefix() {
  .schedule-browser .controls-container select {
    padding: 0.5rem 1.25rem 1.1rem 0.8rem;
  }
}
.schedule-browser .schedule-content {
  clear: both;
  margin-top: 1em;
}
.schedule-browser .schedule-content .episode {
  border-top: 1px solid #a0a0a0;
  margin: 0;
  overflow: hidden;
  padding: 1em 0 0;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode {
    max-width: 79rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
  .schedule-browser .schedule-content .episode::after {
    clear: both;
    content: "";
    display: table;
  }
}
.schedule-browser .schedule-content .episode > div {
  padding: 0 1rem;
}
.schedule-browser .schedule-content .episode > div.show-image {
  margin-top: 1rem;
  padding: 0;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode > div.show-image {
    margin-top: 0;
  }
}
.schedule-browser .schedule-content .episode .episode-time {
  float: left;
  font-size: 2em;
  letter-spacing: 0.07em;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode .episode-time {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 10.4370548518%;
    padding-top: 1rem;
  }
  .schedule-browser .schedule-content .episode .episode-time:last-child {
    margin-right: 0;
  }
}
.schedule-browser .schedule-content .episode .episode-time .minute {
  margin-left: 0.05rem;
  position: relative;
  top: 0.12em;
  vertical-align: super;
  font-size: 0.7em;
}
.schedule-browser .schedule-content .episode .episode-time .ampm {
  position: relative;
  top: 0.12em;
  vertical-align: super;
  font-size: 0.6em;
  color: #a0a0a0;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode .episode-info {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%;
    padding-top: 1rem;
  }
  .schedule-browser .schedule-content .episode .episode-info:last-child {
    margin-right: 0;
  }
}
.schedule-browser .schedule-content .episode .episode-info .indicator {
  background-color: #f27200;
  float: left;
  font-size: 0.8em;
  padding: 0.2em 0.5em 0 0.7em;
  text-transform: uppercase;
}
.schedule-browser .schedule-content .episode .episode-info .indicator.on-now {
  background-color: #f7b500;
  color: black;
  font-weight: bold;
}
.schedule-browser .schedule-content .episode .episode-info .indicator.empty {
  display: none;
}
.schedule-browser .schedule-content .episode .episode-info .show-title {
  clear: both;
  padding: 1rem 0 0;
  font-size: 10vw;
  font-weight: 100;
  line-height: 1.5rem;
}
.schedule-browser .schedule-content .episode .episode-info .show-title a {
  text-decoration: none;
}
.schedule-browser .schedule-content .episode .episode-info .show-title a:hover {
  color: #a1a1a1;
}
@media screen and (min-width: 26rem) {
  .schedule-browser .schedule-content .episode .episode-info .show-title {
    font-size: 2rem;
    line-height: 2rem;
    margin: 0 0 1rem;
    padding: 0.5rem 0 0;
  }
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode .episode-info .episode-title {
    font-size: 1rem;
    margin: 0 0 0.5rem;
  }
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode .episode-info .episode-synopsis {
    font-size: 0.8rem;
    line-height: 1.1rem;
    padding-bottom: 0.8rem;
  }
}
.schedule-browser .schedule-content .episode .show-image {
  float: right;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .schedule-browser .schedule-content .episode .show-image {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 44.5562720511%;
  }
  .schedule-browser .schedule-content .episode .show-image:last-child {
    margin-right: 0;
  }
}
.schedule-browser .schedule-content .episode .show-image img {
  width: 100%;
  float: right;
  height: auto;
}
/* @file
 * Style definitions for BeSafe sections for Justice Network.
 */
.section-besafe {
  background-color: white;
  color: #f7b500;
}
.section-besafe .carousel-slide {
  color: white;
}
.section-besafe .hero-container {
  min-height: 0;
}
.section-besafe .hero-title {
  max-width: 30rem;
}
@media screen and (min-width: 49rem) {
  .section-besafe .hero-title {
    top: 12rem;
  }
}
.section-besafe h2 {
  color: #f27200;
}
.section-besafe .hero-title {
  color: white;
  text-align: left;
}
.section-besafe #page {
  text-align: center;
}
.section-besafe #content {
  padding: 0;
}
.section-besafe #footer {
  color: white;
  text-align: left;
}
.section-besafe .panel-pane {
  overflow: visible;
  padding: 0 1rem;
}
.section-besafe h1.title {
  display: none;
}
.section-besafe .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image {
  margin: 20% 0 0 7%;
  max-width: 60%;
}
.section-besafe .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image img {
  height: auto;
  width: 100%;
}
.section-besafe .video {
  margin-bottom: -12%;
  position: relative;
}
.section-besafe .video:before {
  display: block;
  content: "";
  padding-top: 74.21875%;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .section-besafe .video:before {
    margin-bottom: -15%;
  }
}
.section-besafe .video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 74.21875%;
}
@media screen and (min-width: 49rem) {
  .section-besafe .video {
    margin-left: auto;
    margin-right: auto;
    max-width: 89%;
  }
}
.section-besafe .inside-content-wrap {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.section-besafe .details {
  font-size: 1rem;
  line-height: 1.1rem;
  margin-bottom: 1rem;
  padding-left: 1rem;
  position: relative;
  text-align: left;
}
@media screen and (min-width: 49rem) {
  .section-besafe .details {
    float: right;
    width: 65%;
  }
}
.section-besafe .details a {
  color: #f7b500;
}
.section-besafe .details .field-group-div > .field {
  margin-bottom: 0.5rem;
}
.section-besafe .details .field-label {
  font-weight: bold;
}
@media screen and (min-width: 49rem) {
  .section-besafe .section-cta {
    position: absolute;
    right: 5.5%;
    top: 0;
  }
}
.section-besafe .section-cta #social-share {
  font-size: 0.9rem;
  margin: 0 auto 1rem;
}
@media screen and (min-width: 49rem) {
  .section-besafe .subject-image {
    float: left;
    padding-left: 5%;
    width: 30%;
  }
}
.section-besafe .subject-image img {
  height: auto;
  max-width: 100%;
}
.section-besafe .subject-image .image-title {
  font-size: 1rem;
  margin-bottom: 2rem;
}
.section-besafe .subject-image .image-title > * {
  display: inline-block;
}
.section-besafe .subject-image .image-title .field-label {
  display: none;
}

.pane-menu-menu-besafe {
  background-color: #dddcdc;
}
.pane-menu-menu-besafe .menu {
  display: inline-block;
  padding: 0;
  text-align: center;
}
.pane-menu-menu-besafe .menu li {
  display: flex;
}
@media screen and (min-width: 49rem) {
  .pane-menu-menu-besafe .menu li {
    display: inline-block;
  }
}
.pane-menu-menu-besafe .menu li a {
  background-color: #f27200;
  color: white;
  font-size: 0.9em;
  margin: 0 auto 0.5rem;
  padding: 0.5rem 1rem 0.6rem;
  text-decoration: none;
  text-transform: uppercase;
}
.pane-menu-menu-besafe .menu li a:hover {
  text-decoration: underline;
}
.pane-menu-menu-besafe .menu li.active-trail a {
  background-color: #f7b500;
}

#market-nav {
  color: white;
}
#market-nav select {
  font-size: 0.7em;
  padding: 0.4em 1.25em 0.5em 0.5em;
  text-transform: uppercase;
}
@media screen and (min-width: 49rem) {
  #market-nav select {
    font-size: 1em;
  }
}

.pane-most-wanted-pane h2,
.pane-missing-children-pane h2 {
  margin-bottom: 0;
}
.pane-most-wanted-pane > .pane-content,
.pane-missing-children-pane > .pane-content {
  clear: both;
}

.pane-most-wanted-pane .pane-title,
.pane-missing-children-pane .pane-title,
.pane-safety-tips .pane-title {
  color: black;
}
@media screen and (min-width: 49rem) {
  .pane-most-wanted-pane .pane-title,
.pane-missing-children-pane .pane-title,
.pane-safety-tips .pane-title {
    text-align: left;
  }
}
.pane-most-wanted-pane .pane-title .highlight,
.pane-missing-children-pane .pane-title .highlight,
.pane-safety-tips .pane-title .highlight {
  color: #f27200;
}
.pane-most-wanted-pane img,
.pane-missing-children-pane img,
.pane-safety-tips img {
  height: auto;
  max-width: 100%;
}
.pane-most-wanted-pane .more-link,
.pane-missing-children-pane .more-link,
.pane-safety-tips .more-link {
  margin: 0 auto 1rem;
}
@media screen and (min-width: 26rem) {
  .pane-most-wanted-pane .pane-content,
.pane-missing-children-pane .pane-content,
.pane-safety-tips .pane-content {
    overflow: hidden;
  }
}
@media screen and (min-width: 26rem) {
  .pane-most-wanted-pane .views-row,
.pane-missing-children-pane .views-row,
.pane-safety-tips .views-row {
    float: left;
    margin-right: 1.5%;
    width: 49.25%;
  }
  .pane-most-wanted-pane .views-row:nth-child(2n+2),
.pane-missing-children-pane .views-row:nth-child(2n+2),
.pane-safety-tips .views-row:nth-child(2n+2) {
    margin-right: 0;
  }
}
.pane-most-wanted-pane .video,
.pane-missing-children-pane .video,
.pane-safety-tips .video {
  position: relative;
}
.pane-most-wanted-pane .video:before,
.pane-missing-children-pane .video:before,
.pane-safety-tips .video:before {
  display: block;
  content: "";
  padding-top: 74.21875%;
  width: 100%;
}
@media screen and (min-width: 49rem) {
  .pane-most-wanted-pane .video:before,
.pane-missing-children-pane .video:before,
.pane-safety-tips .video:before {
    margin-bottom: -12%;
  }
}
.pane-most-wanted-pane .video > iframe,
.pane-missing-children-pane .video > iframe,
.pane-safety-tips .video > iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 74.21875%;
}

.view-besafe-markets-navigation {
  font-size: 5vw;
  line-height: normal;
  margin: 1rem auto 0;
}
@media screen and (min-width: 23rem) {
  .view-besafe-markets-navigation {
    font-size: 1rem;
  }
}
@media screen and (min-width: 49rem) {
  .view-besafe-markets-navigation {
    float: right;
    margin-top: 1.5rem;
  }
}

#goto-market:hover {
  cursor: pointer;
}

.missing-page-title {
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
  padding-top: 3rem;
}
@media screen and (min-width: 49rem) {
  .missing-page-title {
    font-size: 2rem;
    margin-left: 5.5%;
    padding-bottom: 0.7rem;
    padding-top: 6rem;
    text-align: left;
  }
}
.missing-page-title > * {
  display: inline-block;
}
.missing-page-title .field-label {
  display: none;
}

.view-missing-children .view-content,
.view-most-wanted .view-content {
  clear: both;
}

/* @file
 * Justice Network slideshow styles
 */
.pane-bundle-slideshow {
  margin: 0 -1rem;
}
@media screen and (min-width: 23rem) {
  .pane-bundle-slideshow {
    margin: 0 -2rem;
  }
}
.pane-bundle-slideshow .slideshow-slide {
  width: 100%;
  height: 0;
  padding-bottom: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 25rem) {
  .pane-bundle-slideshow .slideshow-slide {
    padding-bottom: 70%;
  }
}
@media screen and (min-width: 32rem) {
  .pane-bundle-slideshow .slideshow-slide {
    padding-bottom: 60%;
  }
}
@media screen and (min-width: 44rem) {
  .pane-bundle-slideshow .slideshow-slide {
    padding-bottom: 48.5%;
  }
}
.pane-bundle-slideshow .slideshow-slide .slide-content {
  left: 0;
  width: 100%;
  height: 100%;
  padding: 5.5em 1.75em 2.5em;
}
@media screen and (min-width: 32rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content {
    padding: 7em 1.75em 1em;
  }
}
@media screen and (min-width: 49rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content {
    padding: 8em 2.5em 1em;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.align-left {
    text-align: left;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.align-right {
    text-align: right;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.align-center {
    text-align: center;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.width-33 {
    width: 33%;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.width-50 {
    width: 50%;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content.width-67 {
    width: 67%;
  }
}
.pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-overlay-text h1 {
  font-size: 2em;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-overlay-text h1 {
    font-size: 56px;
  }
}
@media screen and (min-width: 62rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-overlay-text h1 {
    font-size: 72px;
  }
}
@media screen and (min-width: 73rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-overlay-text h1 {
    font-size: 88px;
  }
}
@media screen and (min-width: 84rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-overlay-text h1 {
    font-size: 102px;
  }
}
.pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-link a {
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  background-color: #f27200;
  border: none;
  padding: 0.8em 1.2em;
  text-decoration: none;
}
.pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-link a:hover {
  text-decoration: underline;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-slide-link a {
    font-size: 0.7em;
  }
}
.pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image {
  width: 100%;
}
@media screen and (min-width: 32rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image {
    width: 50%;
  }
}
@media screen and (min-width: 44rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image {
    width: 85%;
  }
}
@media screen and (min-width: 66rem) {
  .pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image {
    width: 100%;
  }
}
.pane-bundle-slideshow .slideshow-slide .slide-content .field-name-field-overlay-image img {
  max-width: 100%;
  height: 100%;
  margin-bottom: 1em;
}
.pane-bundle-slideshow .slick-dots {
  bottom: -10px;
  text-align: right;
  padding-right: 1.75em;
}
@media screen and (min-width: 49rem) {
  .pane-bundle-slideshow .slick-dots {
    padding-right: 2.5em;
  }
}
.pane-bundle-slideshow .slick-dots li {
  margin: 0;
}
.pane-bundle-slideshow .slick-dots li.slick-active {
  color: #f27200;
}
.pane-bundle-slideshow .slick-dots li.slick-active button:before {
  width: 1.5em;
  height: 1.5em;
  font-size: 20px;
  content: "●";
  color: #f27200;
}
.pane-bundle-slideshow .slick-dots li button {
  width: 1.5em;
  height: 1.5em;
}
.pane-bundle-slideshow .slick-dots li button:before {
  width: 1.5em;
  height: 1.5em;
  font-size: 18px;
  content: "⚬";
  color: white;
  font-weight: bold;
}
.pane-bundle-slideshow.slideshow-bottom .slideshow-slide {
  padding-bottom: 100%;
}
@media screen and (min-width: 25rem) {
  .pane-bundle-slideshow.slideshow-bottom .slideshow-slide {
    padding-bottom: 80%;
  }
}
@media screen and (min-width: 32rem) {
  .pane-bundle-slideshow.slideshow-bottom .slideshow-slide {
    padding-bottom: 50%;
  }
}
@media screen and (min-width: 50rem) {
  .pane-bundle-slideshow.slideshow-bottom .slideshow-slide {
    padding-bottom: 35%;
  }
}
.pane-bundle-slideshow.slideshow-bottom .slideshow-slide .slide-content {
  padding-top: 2em;
}
.pane-bundle-slideshow.slideshow-bottom .slideshow-slide .slide-content .field-name-field-slide-link a {
  margin-top: 1em;
}
@media screen and (min-width: 73rem) {
  .pane-bundle-slideshow.slideshow-bottom .slideshow-slide .slide-content .field-name-field-slide-link a {
    margin-top: 5em;
  }
}
.pane-bundle-slideshow .slick-slider {
  margin-bottom: 0;
}

/* @file
 * Styles for Statistics Pair bean for Justice Network.
 */
.bean-statistics-pair {
  width: 100%;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
}
.bean-statistics-pair::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 41em) {
  .bean-statistics-pair .content {
    width: 100%;
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 5.5em;
  }
}
.bean-statistics-pair .content .statistic {
  height: 6.5em;
}
@media screen and (min-width: 41em) {
  .bean-statistics-pair .content .statistic {
    display: table-cell;
    width: 50%;
    vertical-align: middle;
    height: auto;
  }
}
.bean-statistics-pair .content .statistic .statistic-inner {
  height: 4.5em;
  display: table;
  width: 100%;
}
.bean-statistics-pair .content .statistic .statistic-inner .number {
  display: table-cell;
  color: #f27200;
  width: 35%;
  height: 2.5em;
  padding: 0.2em 0 0;
  font-size: 2.5em;
  letter-spacing: 0;
  text-align: right;
  background-image: url(../images/statistic-shield.png);
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  background-size: 55px auto;
}
@media screen and (min-width: 49rem) {
  .bean-statistics-pair .content .statistic .statistic-inner .number {
    background-size: 48px auto;
    font-size: 1.75em;
    padding: 0;
  }
}
@media screen and (min-width: 62rem) {
  .bean-statistics-pair .content .statistic .statistic-inner .number {
    background-size: 55px auto;
  }
}
@media screen and (min-width: 84rem) {
  .bean-statistics-pair .content .statistic .statistic-inner .number {
    background-size: 68px auto;
  }
}
.bean-statistics-pair .content .statistic .statistic-inner .number .field-items {
  margin-top: 0.85em;
  margin-left: auto;
  margin-right: 0.5em;
  width: 1.9em;
  text-align: center;
}
.bean-statistics-pair .content .statistic .statistic-inner .text {
  width: 65%;
  display: table-cell;
  vertical-align: middle;
}
.bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-1-line-1, .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-2-line-1 {
  font-size: 0.9em;
  margin-bottom: 0.3em;
  margin-left: 0.1em;
}
@media screen and (min-width: 49rem) {
  .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-1-line-1, .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-2-line-1 {
    font-size: 0.72em;
  }
}
.bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-1-line-2, .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-2-line-2 {
  font-size: 2.2em;
}
@media screen and (min-width: 49rem) {
  .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-1-line-2, .bean-statistics-pair .content .statistic .statistic-inner .text .field-name-field-stat-2-line-2 {
    font-size: 2.25em;
  }
}
.bean-statistics-pair .statistic-1 {
  background-image: url(../images/stat-1-bg.png);
}
.bean-statistics-pair .statistic-2 {
  background-image: url(../images/stat-2-bg.png);
}

/* @file
 * Styles for the time zone selector/schedule page.
 */
.pane-jn-tz-manager-widget {
  max-width: 79rem;
  margin-left: auto;
  margin-right: auto;
  margin-left: -1rem;
  margin-right: -1rem;
  max-height: 99px;
}
.pane-jn-tz-manager-widget .show-hide {
  display: none;
}
.pane-jn-tz-manager-widget .show-hide .show-hide-button {
  display: inline-block;
  padding: 0 1em;
  margin: 0;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  font-size: 0.5rem;
  letter-spacing: 0.1em;
  line-height: 2;
  text-transform: uppercase;
  background-color: #363636;
  position: relative;
  top: 1px;
}
@media screen and (min-width: 73rem) {
  .pane-jn-tz-manager-widget .show-hide .show-hide-button {
    top: 3px;
  }
}
@media screen and (min-width: 49rem) {
  .pane-jn-tz-manager-widget .show-hide .show-hide-button {
    top: 2px;
  }
}
.pane-jn-tz-manager-widget .show-hide .show-hide-button:hover {
  cursor: pointer;
}
.pane-jn-tz-manager-widget::after {
  clear: both;
  content: "";
  display: table;
}
@media screen and (min-width: 23rem) {
  .pane-jn-tz-manager-widget {
    margin-left: -2rem;
    margin-right: -2rem;
    max-height: 124px;
  }
}
.pane-jn-tz-manager-widget .pane-content {
  width: 100%;
  display: table;
  width: 100%;
  table-layout: fixed;
}
.pane-jn-tz-manager-widget .pane-content .form-item-tzSelect {
  display: table-cell;
  width: 33.3333333333%;
  background-color: #828282;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  vertical-align: middle;
}
@media screen and (min-width: 49rem) {
  .pane-jn-tz-manager-widget .pane-content .form-item-tzSelect {
    display: table-cell;
    width: 16.6666666667%;
    padding-left: 2rem;
  }
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget {
  display: table-cell;
  width: 66.6666666667%;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #505050;
}
@media screen and (min-width: 49rem) {
  .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget {
    display: table-cell;
    width: 83.3333333333%;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper a {
  color: inherit;
  text-decoration: none;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper a:hover {
  text-decoration: underline;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .time {
  font-size: 0.8em;
  font-weight: 300;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .show-title {
  font-size: 0.9em;
  letter-spacing: 0;
  font-family: Poppins, sans-serif;
  text-transform: uppercase;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .jn-tz-indicator-wrapper {
  float: left;
  height: 4.5em;
  margin: 0 0.5em;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .jn-tz-indicator-wrapper .indicator {
  display: inline-block;
  font-size: 0.5em;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  color: white;
  text-transform: uppercase;
  background-color: #03527c;
  min-width: 5em;
  text-align: center;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .jn-tz-indicator-wrapper .indicator.current {
  color: black;
  background-color: #f7b500;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .jn-tz-indicator-wrapper .indicator.next {
  background-color: #f27200;
}
@media screen and (min-width: 49rem) {
  .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .jn-tz-episode-wrapper .jn-tz-indicator-wrapper .indicator {
    padding: 0 0.8em 0 1em;
    min-width: 7em;
  }
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-prev, .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-next {
  top: 60%;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-prev:before, .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-next:before {
  color: #808080;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-prev {
  left: -18px;
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-next {
  right: -3em;
}
@media screen and (min-width: 23rem) {
  .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-next {
    right: -1em;
  }
}
@media screen and (min-width: 62rem) {
  .pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-next {
    right: 0;
  }
}
.pane-jn-tz-manager-widget .pane-content .jn-tz-schedule-widget .slick-slider {
  margin-bottom: 0;
}
.pane-jn-tz-manager-widget.sticky {
  position: fixed;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-width: 1360px;
}
.pane-jn-tz-manager-widget.sticky .show-hide {
  display: block;
}

/* @file
 * Justice Network upcoming episodes block.
 */
.block-upcoming-episodes {
  margin-bottom: 2em;
}
@media screen and (min-width: 49rem) {
  .block-upcoming-episodes {
    max-width: 79rem;
    margin-left: auto;
    margin-right: auto;
  }
  .block-upcoming-episodes::after {
    clear: both;
    content: "";
    display: table;
  }
  .block-upcoming-episodes .column-empty {
    display: table-cell;
    width: 25%;
  }
  .block-upcoming-episodes .column-main {
    display: table-cell;
    width: 75%;
  }
}
.block-upcoming-episodes .column-empty {
  height: 1px;
  margin: 0;
  padding: 0;
}
.block-upcoming-episodes h3.title {
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.2em;
  letter-spacing: 0.2em;
}
.block-upcoming-episodes .upcoming-episode {
  border-top: 1px solid #d0d0d0;
}
.block-upcoming-episodes .upcoming-episode:last-child {
  border-bottom: 1px solid #d0d0d0;
}
.block-upcoming-episodes .upcoming-episode .episode-date {
  padding: 1em 0 0.5em;
  font-family: Poppins, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 2em;
}
.block-upcoming-episodes .upcoming-episode .episode-date span.ordinal {
  vertical-align: super;
  font-size: 0.6em;
}
@media screen and (min-width: 62rem) {
  .block-upcoming-episodes .upcoming-episode .episode-date {
    float: left;
    width: 6em;
  }
}
.block-upcoming-episodes .upcoming-episode .episode-details {
  padding: 0.5em 0 2em;
  display: table;
}
@media screen and (min-width: 62rem) {
  .block-upcoming-episodes .upcoming-episode .episode-details {
    padding-top: 2em;
  }
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-time {
  display: table-cell;
  width: 3.5em;
  font-size: 2.2em;
  border-right: 1px solid #d0d0d0;
  vertical-align: top;
  padding-top: 0.2em;
}
@media screen and (min-width: 62rem) {
  .block-upcoming-episodes .upcoming-episode .episode-details .episode-time {
    padding-left: 1em;
    border-right: none;
    border-left: 1px solid #d0d0d0;
  }
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-time span.minute {
  font-size: 0.7em;
  position: relative;
  top: -0.3em;
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-time span.ampm {
  font-size: 0.7em;
  position: relative;
  top: -0.3em;
  left: 0.1em;
  color: #909090;
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-info {
  display: table-cell;
  padding: 0 0 1em 1.5em;
  vertical-align: top;
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-info h4.episode-title {
  font-size: 0.9em;
  margin-top: 0;
}
.block-upcoming-episodes .upcoming-episode .episode-details .episode-info .episode-synopsis {
  font-size: 0.9em;
}