/* @file
 * Styling for RSS link in Views output.
 */
.pane-rss-link {
  letter-spacing: 0.05em;
  float: right;
  clear: right;
  margin-bottom: 0.7em;
  p {
    margin: 0.1em 0 0;
    a {
      text-decoration: none;
      color: $light-orange;
      &:visited {
        color: $light-orange;
      }
      &:hover {
        color: $text-hover-grey;
      }
      img {
        width: 1.3em;
        height: auto;
        margin-right: 0.3em;
        position: relative;
        top: 0.3em;
      }
    }
  }
}
