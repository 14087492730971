/* @file
 * Style definitions for BeSafe sections for Justice Network.
 */

.section-besafe {
  @include alt-page-bg($of: visible);
  h1.title {
    display: none;
  }
  .pane-bundle-slideshow .slideshow-slide .slide-content {
    .field-name-field-overlay-image {
      margin: 20% 0 0 7%;
      max-width: 60%;
      img {
        height: auto;
        width: 100%
      }
    }
  }
  .video {
    margin-bottom: -12%;
    @include video-responsive($mb: -15%);
    @include media($breakpoint-min-medium) {
      margin-left: auto;
      margin-right: auto;
      max-width: 89%;
    }
  }
  .inside-content-wrap {
    overflow: hidden;
    position: relative;
    z-index: 2;
  }
  .details {
    font-size: 1rem;
    line-height: 1.1rem;
    margin-bottom: 1rem;
    padding-left: 1rem;
    position: relative;
    text-align: left;
    @include media($breakpoint-min-medium) {
      float: right;
      width: 65%;
    }
    a {
      color: $light-orange;
    }
    .field-group-div {
      > .field {
        margin-bottom: 0.5rem;
      }
    }
    .field-label {
      font-weight: bold;
    }
  }
  .section-cta {
    @include media($breakpoint-min-medium) {
      position: absolute;
      right: 5.5%;
      top: 0;
    }
    #social-share {
      font-size: 0.9rem;
      margin: 0 auto 1rem;
    }
  }
  .subject-image {
    @include media($breakpoint-min-medium) {
      float: left;
      padding-left: 5%;
      width: 30%;
    }
    img {
      height: auto;
      max-width: 100%;
    }
    .image-title {
      font-size: 1rem;
      margin-bottom: 2rem;
      > * {
        display: inline-block;
      }
      .field-label {
        display: none;
      }
    }
  }
}

.pane-menu-menu-besafe {
  background-color: $background-light-grey;
  .menu {
    display: inline-block;
    padding: 0;
    text-align: center;
    li {
      display: flex;
      @include media($breakpoint-min-medium) {
        display: inline-block;
      }
      a {
        background-color: $dark-orange;
        color: white;
        font-size: 0.9em;
        margin: 0 auto 0.5rem;
        padding: 0.5rem 1rem 0.6rem;
        text-decoration: none;
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
        }
      }
      &.active-trail {
        a {
          background-color: $light-orange;
        }
      }
    }
  }
}

#market-nav {
  color: white;
  select {
    font-size: 0.7em;
    padding: 0.4em 1.25em 0.5em 0.5em;
    text-transform: uppercase;
    @include media($breakpoint-min-medium) {
      font-size: 1em;
    }
  }
}

.pane-most-wanted-pane,
.pane-missing-children-pane {
  h2 {
    margin-bottom: 0;
  }
  > .pane-content {
    clear: both;
  }
}

.pane-most-wanted-pane,
.pane-missing-children-pane,
.pane-safety-tips {
  .pane-title {
    color: black;
    @include media($breakpoint-min-medium) {
      text-align: left;
    }
    .highlight {
      color: $dark-orange;
    }
  }
  img {
    height: auto;
    max-width: 100%;
  }
  .more-link {
    margin: 0 auto 1rem;
  }
  .pane-content {
    @include media($breakpoint-min-mobile-wider) {
      overflow: hidden;
    }
  }
  .views-row {
    @include media($breakpoint-min-mobile-wider) {
      @include simple-grid($rows: 2);
    }
  }
  .video {
    @include video-responsive();
  }
}

.view-besafe-markets-navigation {
  font-size: 5vw;
  line-height: normal;
  margin: 1rem auto 0;
  @include media($breakpoint-min-mobile-wide) {
    font-size: 1rem;
  }
  @include media($breakpoint-min-medium) {
    float: right;
    margin-top: 1.5rem;
  }
}

#goto-market {
  &:hover {
    cursor: pointer;
  }
}

.missing-page-title {
  font-size: 1.3rem;
  padding-bottom: 0.5rem;
  padding-top: 3rem;
  @include media($breakpoint-min-medium) {
    font-size: 2rem;
    margin-left: 5.5%;
    padding-bottom: 0.7rem;
    padding-top: 6rem;
    text-align: left;
  }
  > * {
    display: inline-block;
  }
  .field-label {
    display: none;
  }
}

.view-missing-children,
.view-most-wanted {
  .view-content {
    clear: both;
  }
}
