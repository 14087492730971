/* @file
 * Justice Network header styles
 */

#header a.menu-toggle {
  display: none;
  @include media($breakpoint-mobile) {
    display: block;
    float: right;
    background-image: url(../images/nav-hamburger.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 2em;
    padding: 1.25em;
    margin-top: 0.3em;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
}

#header-logo {
  display: block;
  margin: 0;
  @include media($breakpoint-min-medium) {
    display: table-cell;
    vertical-align: middle;
  }
  a:hover img {
    opacity: 0.85;
  }
  img {
    height: auto;
    max-width: 110px;
    @include media($breakpoint-min-medium) {
      max-width: 100%;
    }
    &.logo-white {
      display: none;
    }
    &.logo-default {
      display: block;
    }
  }
}
#region-header-wrapper {
  display: table-cell;
}
#header-wrapper {
  width: 100%;
  background-image: linear-gradient(rgba(46, 44, 45, 222), rgba(46, 44, 45, 0));
  padding: 1em 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  &.mobile-menu-open {
    background-color: $dark-orange;
    background-image: none;
    padding-top: 1.4rem;
    position: absolute;
    top: -1.1em;
    left: 0;
    #region-header-wrapper {
      display: block;
    }
    #header-logo {
      img.logo-default {
        display: none;
      }
      img.logo-white {
        display: block;
        width: 13em;
      }
    }
    a.menu-toggle {
      background-image: url(../images/nav-mobile-close.png);
    }
  }
}

@include media($breakpoint-mobile) {
  #region-header-wrapper {
    display: none;
  }
}

#region-header {
  display: table;
  @include media($breakpoint-mobile) {
    display: block;
  }
  width: 100%;
  .nav-header {
    display: table-cell;
    @include media($breakpoint-mobile) {
      display: block;
    }
    vertical-align: middle;
    text-align: right;
    ul.menu {
      text-align: inherit;
      padding: 0;
      margin: 0;
      li {
        color: white;
        display: inline;
        list-style: none;
        margin: .5em;
        a {
          color: white;
          text-decoration: none;
          text-transform: uppercase;
          font-size: .7em;
          font-weight: 200;
          &:hover {
            color: $text-hover-grey;
          }
        }
      }
      @include media($breakpoint-mobile) {
        text-align: left;
        margin-top: 2.5em;
        li {
          display: block;
          padding: 1.25em 0;
          margin: 0;
          border-bottom: 1px solid $dark-orange;
          a {
            font-size: 1.25em;
          }
        }
      }
    }
  }
  .icon-list-header {
    display: table-cell;
    vertical-align: middle;
    width: 8em;
    @include media($breakpoint-mobile) {
      display: block;
      margin: 0;
      width: 100%;
      border-bottom: 1px solid $dark-orange;
    }
    .field-name-field-linked-icons > .field-items {
      display: table-row;
      &> .field-item {
        display: table-cell;
        vertical-align: middle;
        padding: 0.5em;
        svg {
          stroke: white;
          fill: white;
          width: 1em;
          height: 1em;
          &:hover {
            stroke: $dark-orange;
            fill: $dark-orange;
          }
        }
        @include media($breakpoint-mobile) {
          padding: 1em 2.5em 0.75em 0;
          svg {
            width: 1.25em;
            height: 1.25em;
            &:hover {
              stroke: $text-hover-grey;
              fill: $text-hover-grey;
            }
          }
        }
      }
    }
  }
  .header-find-justice {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    width: 19em;
    a.watch-stream {
      font-size: .65em;
      font-weight: 200;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.8em 1.5em 0.4em 1.5em;
      margin-right: 1.5em;
      border: 1px solid white;
      background-color: white;
      color: black;
      &:hover {
        border-color: $dark-orange;
        background-color: $dark-orange;
        color: white;
      }
    }
    a.find-justice {
      font-size: .65em;
      font-weight: 200;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      padding: 0.8em 3em 0.4em 1.5em;
      border: 1px solid white;
      background-image: url(../images/location-icon.png);
      background-position: right 1em center;
      background-repeat: no-repeat;
      &:hover {
        border-color: $dark-orange;
        background-color: $dark-orange;
      }
    }
    @include media($breakpoint-mobile) {
      display: block;
      width: 17em;
      padding-bottom: 1em;
      a.watch-stream {
        font-size: 1.25em;
        display: block;
        margin: 3em 0 1em;
        padding: 1em;
        text-align: center;
      }
      a.find-justice {
        font-size: 1.25em;
        padding: 1.25em 4em 1em 1.5em;
        margin: 1em 0;
        display: block;
        &:hover {
          border-color: $text-hover-grey;
          background-color: $text-hover-grey;
        }
      }
    }
  }
}
