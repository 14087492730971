@charset "UTF-8";
/* @file
 * Justice Network slideshow styles
 */

$breakpoint-slideshow-1: new-breakpoint(min-width 25rem);
$breakpoint-slideshow-2: new-breakpoint(min-width 32rem);
$breakpoint-slideshow-3: new-breakpoint(min-width 44rem);
$breakpoint-slideshow-4: new-breakpoint(min-width 66rem);

.pane-bundle-slideshow {
  margin: 0 -1rem;
  @include media($breakpoint-min-mobile-wide) {
    margin: 0 -2rem;
  }
  .slideshow-slide {
    width: 100%;
    height: 0;
    padding-bottom: 80%;
    @include media($breakpoint-slideshow-1) {
      padding-bottom: 70%;
    }
    @include media($breakpoint-slideshow-2) {
      padding-bottom: 60%;
    }
    @include media($breakpoint-slideshow-3) {
      padding-bottom: 48.5%;
    }
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .slide-content {
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5.5em 1.75em 2.5em;
      @include media($breakpoint-slideshow-2) {
        padding: 7em 1.75em 1em;
      }
      @include media($breakpoint-min-medium) {
        padding: 8em 2.5em 1em;
        &.align-left {
          text-align: left;
        }
        &.align-right {
          text-align: right;
        }
        &.align-center {
          text-align: center;
        }
        &.width-33 {
          width: 33%;
        }
        &.width-50 {
          width: 50%;
        }
        &.width-67 {
          width: 67%;
        }
      }
      .field-name-field-slide-overlay-text {
        h1 {
          font-size: 2em;
          @include media($breakpoint-min-medium) {
            font-size: 56px;
          }
          @include media($breakpoint-min-large) {
            font-size: 72px;
          }
          @include media($breakpoint-min-xlarge) {
            font-size: 88px;
          }
          @include media($breakpoint-min-full) {
            font-size: 102px;
          }
        }
      }
      .field-name-field-slide-link a {
        @include blue-button();
      }
      .field-name-field-overlay-image {
        width: 100%;
        @include media($breakpoint-slideshow-2) {
          width: 50%;
        }
        @include media($breakpoint-slideshow-3) {
          width: 85%;
        }
        @include media($breakpoint-slideshow-4) {
          width: 100%;
        }
        img {
          max-width: 100%;
          height: 100%;
          margin-bottom: 1em;
        }
      }
    }
  }
  .slick-dots {
    bottom: -10px;
    text-align: right;
    padding-right: 1.75em;
    @include media($breakpoint-min-medium) {
      padding-right: 2.5em;
    }
    li {
      margin: 0;
      &.slick-active {
        color: $dark-orange;
        button:before {
          width: 1.5em;
          height: 1.5em;
          font-size: 20px;
          content: "●";
          color: $dark-orange;
        }
      }
      button {
        width: 1.5em;
        height: 1.5em;
        &:before {
          width: 1.5em;
          height: 1.5em;
          font-size: 18px;
          content: "⚬";
          color: white;
          font-weight: bold;
        }
      }
    }
  }
  &.slideshow-bottom {
    .slideshow-slide {
      padding-bottom: 100%;
      @include media($breakpoint-slideshow-1) {
        padding-bottom: 80%;
      }
      @include media($breakpoint-slideshow-2) {
        padding-bottom: 50%;
      }
      @include media(new-breakpoint(min-width 50rem)) {
        padding-bottom: 35%;
      }
      .slide-content {
        padding-top: 2em;
        .field-name-field-slide-link a {
          margin-top: 1em;
          @include media($breakpoint-min-xlarge) {
            margin-top: 5em;
          }
        }
      }
    }
  }
  .slick-slider {
    margin-bottom: 0;
  }
}
