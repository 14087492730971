/* Styles for the magnific popup alert modal */
.popup-alert {
  padding: 1em 2em 2em;
  background-color: black;
  border: 1px solid white;
  width: 80%;
  margin-left: 10%;
  @include media($breakpoint-medium) {
    width: 50%;
    margin-left: 25%;
  }
  .dismiss {
    padding: 0 1em;
    background-color: $dark-grey;
  }
}