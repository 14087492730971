/* @file
 * Styles for the Footer Middle section of the Justice Network site.
 */
.footer-middle {
  background-color: $light-grey;
  .content {
    @include media($breakpoint-min-large) {
      display: table;
    }
    width: 100%;
    .footer-nav {
      @include media($breakpoint-min-large) {
        display: table-cell;
        width: 50%;
      }
      .footer-nav-columns {
        @include media($breakpoint-min-large) {
          display: table;
          width: 100%;
        }
        .block {
          @include media($breakpoint-min-large) {
            display: table-cell;
            width: 33.3%;
            padding-right: 0.5em;
          }
          .title {
            display: none;
            @include media($breakpoint-min-large) {
              display: block;
              font-family: $galano;
              font-size: 0.725em;
              letter-spacing: 0;
              color: black;
              text-transform: uppercase;
            }
          }
          ul {
            padding: 0;
            margin: 0;
            li {
              list-style: none;
              margin: 0 -2.5em;
              padding: 1em 2.5em 0.7em;
              border-bottom: 1px solid $background-light-grey;
              @include media($breakpoint-min-large) {
                margin: 0;
                padding: 0;
                border-bottom: none;
              }
              a {
                font-family: $helvetica-neue;
                font-weight: 200;
                color: $dark-orange;
                letter-spacing: 0.1em;
                text-transform: uppercase;
                text-decoration: none;
                @include media($breakpoint-min-large) {
                  font-size: 0.6em;
                }
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .footer-desc {
      font-family: $galano;
      color: black;
      text-transform: uppercase;
      font-size: 0.7rem;
      letter-spacing: 0;
      font-weight: 700;
      @include media($breakpoint-min-large) {
        display: table-cell;
        width: 50%;
      }
      .block {
        padding: 1rem 0;
        @include media($breakpoint-min-large) {
          padding: 0 1.5rem 0 4rem;
        }
        @include media($breakpoint-min-full) {
          padding: 0 3.5rem 0 6rem;
        }
      }
    }
  }
}
